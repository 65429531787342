import React from 'react'

const MyPage = () => {
  return (
    <div style={{textAlign: 'center',
        padding: '100px'}}>
         <h1>Thank you</h1>
      <p>Developed by Shruti Verma</p>
      <a href="https://github.com/shrutiverma2208/react-wedding-website">GitHub Repository</a>
    </div>
  )
}

export default MyPage