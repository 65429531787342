import React from 'react';
import Mobile from './Mobile.js';

const PhoneNumber = () => {
  return (
    
    <div>
      
      <p>Call At: <Mobile phoneNumber={["906-907-2102","977-391-5609"]} /></p>
    </div>
   
    
  );
};

export default PhoneNumber;